import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import '../stylesheets/item.css';
import logoRFID from './RFID-Icon.svg';
import warehouse_inactive from '../images/pictos_warehouse/warehouse_inactive.png';
import warehouse_checked from '../images/pictos_warehouse/warehouse_checked.png';
import warehouse_notChecked from '../images/pictos_warehouse/warehouse_not-checked.png';
import ItemModal from './itemModal';
import { handleApiResponse } from '../functions/handleApiResponse';
import fetchWithJWT from '../functions/fetchWithJWT';
import { CHECK_STOCK_OUT_RISK_INTERVAL } from '../components/liveStoreReceptionV2/consts'

class Item extends Component {
  constructor(props){
    super(props);
    this.state = {
      stock: null,
      showModal: false,
    }
  }

  componentDidMount(){
    this.getStock()
  }

  getStock = () => {
    let store = this.props.store;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${process.env.REACT_APP_base_URL}/api/${store}/stock/${this.props.item_id}`;

    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse)
    .then(result => {
      this.setState({
        stock: result.stock
      })
    })
    .catch(error => {
      console.warn(`Error in getStock for item ${this.props.item_id} : ${error}`);
    })
  }

  decideWarehouseColor = () => {
    const {
      qtiesReadBeforeGateTotal,
      qtiesPredictedTotal,
    } = this.props;

    // warehouse - read more then expected
    const isGreen = qtiesPredictedTotal <= qtiesReadBeforeGateTotal;
    if (isGreen) {
      return "green";
    }

    return "red";
  }

  displayWarehouse = (warehouseColor) => {
    const { t } = this.props;
    switch (warehouseColor){
      case 'green':
        return (
          <img
            className="warehouseIcon"
            src={warehouse_checked}
            alt="warehouseIcon green"
            title={t(
              'itemComponent.greenTitle',
              'All expected quantities were scanned properly at the warehouse'
            )}
          />
        );
      case 'red':
        return (
          <img
            className="warehouseIcon"
            src={warehouse_notChecked}
            alt="warehouseIcon red"
            title={t(
              'itemComponent.redTitle',
              'Not all quantities were scanned at the warehouse during picking'
            )}
          />
        );
      default:
        return (
          <img
            className="warehouseIcon"
            src={warehouse_inactive}
            alt="warehouseIcon grey"
          />
        );
    }
  }

  displayRobotIcon = (isFoundAtMovements, isMissingAtMovements, isRevisedAtMovements) => {
    const { t } = this.props;
    if (isFoundAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotFoundIcon vtmn-icon_decision"
            title={t('itemComponent.robotFoundTitle', 'Found during inventory')}
          ></i>
        </div>
      );
    }
    if (isMissingAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotMissingIcon vtmn-icon_decision"
            title={t('itemComponent.robotMissingTitle', 'Confirmed missing due to movements')}
          ></i>
        </div>
      );
    }
    if (isRevisedAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotRevisedIcon vtmn-icon_decision"
            title={t('itemComponent.robotRevisedTitle', 'Has movement correction')}
          ></i>
        </div>
      );
    }
  }

  displayRFID = () => {
    const { article_flag } = this.props;
    if (article_flag !== 'P' && article_flag !== 'L'){
      return(
        <img className="not-rfid" src={logoRFID} alt="RFID-icon"></img>
      )
    }
  }

  onModalClose = () => this.setState({ showModal: false });

  displayUnexpectedMatchesSizeIcon = (match) => {
    const { t } = this.props;
    const titleStart = t('itemComponent.unexpectedMatchesSize', 'Same missing quantity for another size of this model');
    const title = `${titleStart} (${match.missingItemId} - ${match.missingItem.item_lib})`;

    return (
      <div className="sizeMatchIconWrapper" key={match.missingItemId}>
        <i className="sizeMatchIcon vtmn-icon_notif_alert"
          title={title}
        ></i>
      </div>
    );
  }

  render() {
    const { showModal, stock } = this.state;
    const {
      t,
      unexpectedMatchesSize,
      qtiesPredictedTotal,
      qtiesReceivedTotal,
      reception,
    } = this.props;
    let myClass = "itemInParcel clickableText";
    if (qtiesPredictedTotal === qtiesReceivedTotal){
      myClass = myClass + " text-success";
    }
    const warehouseColor = this.decideWarehouseColor();
    const picture = "https://contents.mediadecathlon.com/p" + this.props.pixlId + "/a.jpg?f=60x60";
    const stockOutRisk =
      stock !== null &&
      reception &&
      reception.status === 'RECEIVED' &&
      dayjs().diff(reception.status_change_date, 'h') <= CHECK_STOCK_OUT_RISK_INTERVAL &&
      qtiesPredictedTotal - qtiesReceivedTotal >= stock;

    return(
      <div className={`${stockOutRisk ? "stock-out-risk" : ''} list-group-item`}>
        <div className={myClass} onClick={() => this.setState({ showModal: true })}>
          <div className="row d-flex justify-content-between card-body titleRow">
            <div className="mr-auto card-text">
              {this.props.item_id}
              {' : '}
              {t('itemComponent.expected', 'expected')}
              {' '}
              {this.props.qtiesPredictedTotal}
              {' - '}
              {t('itemComponent.read', 'read')}
              {' '}
              {this.props.qtiesReceivedTotal}
            </div>
            {this.displayRFID()}
            {this.displayRobotIcon(
              this.props.isFoundAtMovements,
              this.props.isMissingAtMovements,
              this.props.isRevisedAtMovements,
            )}
            {unexpectedMatchesSize && unexpectedMatchesSize.map(this.displayUnexpectedMatchesSizeIcon)}
            {!!this.props.qtiesPredictedTotal && this.displayWarehouse(warehouseColor)}
          </div>
          <div className="row">
            <div className="col-3 itemImage">
              <p className="price">{this.props.price} <span className="currency">{this.props.currency}</span></p>
              <img src={picture} alt="item" />
            </div>
            <div className="col-9 itemDescription card-text"><span>{this.props.item_lib}</span></div>
          </div>
        </div>
        {stockOutRisk && (
          <div className="stock-out-warning">
            {t('itemComponent.stockOutRisk', 'Stock out risk')}
          </div>
        )}
        <ItemModal
          {...this.props}
          show={showModal}
          onClose={this.onModalClose}
          itemId={this.props.item_id}
          itemLib={this.props.item_lib}
          universeLabel={this.props.universe_label}
          departmentLabel={this.props.department_label}
          subDepartmentLabel={this.props.sub_department_label}
          familyLabel={this.props.family_label}
          brandName={this.props.brand_name}
          modelId={this.props.model_id}
          modelLib={this.props.model_lib}
          articleFlag={this.props.article_flag}
          stock={stock}
        />
      </div>
    )
  }
}

export default withTranslation()(Item);
