export const PAGE_NAME = 'liveStoreReceptionV2';
export const REFRESH_INTERVAL = 10000;
export const VALID_NATURE = 2;
export const NO_SPORT_LABEL = 'NO SPORT';
export const CHECK_STOCK_OUT_RISK_INTERVAL = 36; //hours

export const DEFAULT_FILTERS = {
  bothGatesMissing: false,
  storeGateZero: false,
  hideIncompleteBoxes: false,
  hideControlled: false,
  hideCheaper: false,
  hideCheaperValue: false,
};

export const DEFAULT_FILTER_VALUES = {
  hideCheaper: 0,
  hideCheaperValue: 0,
};

export const MOVEMENT_STATUSES_VARIANT = {
  "CHECKING TIME": 'success',
  "NOT SCANNED": 'danger',
  "NOT RECEIVED": 'info',
  "NOT CONTROLLED": 'warning',
  "CONTROLLED": 'secondary',
};
