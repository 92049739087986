import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Button, Form, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import useRequest from '../../../../functions/useRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../functions/handleApiResponse';
import ErrorMessage from '../../../errorMessage';
import CurrencyFilter from '../../../widgets/filters/CurrencyFilter';
import '../../../../stylesheets/warehouseAdmin.css';

const getDefaultCurrency = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${warehouse}/currency`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
};

const getMinCessionPrices = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/admin`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
};

const saveMinCessionPrices = (user, updateTokens, { warehouse, prices }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/admin`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(prices),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const CessionPriceItem = ({ price, value, currency, delPrice, onChangePrice, onChangeValue, onChangeCurrency }) => {
  return (
    <>
      <Form.Group className="price-value-group">
        <Form.Group className="price-value-row">
          <Form.Label column lg="4" className="text-lg-right">
            <Trans i18nKey="declareToControl.uploading.minPrice">
              Minimum cession price
            </Trans>
            {': '}
          </Form.Label>
          <Form.Control
            step={0.01}
            min={0}
            max={1000000}
            type="number"
            value={price || 0}
            onChange={onChangePrice}
          />
        </Form.Group>
        <Form.Group className="price-value-row">
          <Form.Label column lg="4" className="text-lg-right">
            <Trans i18nKey="declareToControl.uploading.minValue">
              Minimum cession value
            </Trans>
            {': '}
          </Form.Label>
          <Form.Control
            step={0.01}
            min={0}
            max={1000000}
            type="number"
            value={value || 0}
            onChange={onChangeValue}
          />
        </Form.Group>
      </Form.Group>

      <Form.Group className="price-currency">
        <Form.Label className="min-price-label">
          <Trans i18nKey="declareToControl.admin.currency">
            Currency
          </Trans>
          {': '}
        </Form.Label>
        <CurrencyFilter
          selected={currency || ''}
          onChange={onChangeCurrency}
        />
        {!!delPrice && (
          <Button
            className="del-min-price"
            variant="light"
            onClick={delPrice}
          >
            <i className="vtmn-icon_delete"></i>
          </Button>
        )}
      </Form.Group>
    </>
  );
};

const WarehouseAdmin = ({ user, updateTokens }) => {
  const [prices, setPrices] = useState([]);
  const [success, setSuccess] = useState(false);
  const { warehouse } = useParams();

  const [{
    loading: currencyLoading,
    data: currency,
    error: currencyError,
  }, fetchDefaultCurrency] = useRequest(getDefaultCurrency);

  const [{
    loading,
    data,
    error,
  }, fetchMinCessionPrices] = useRequest(getMinCessionPrices);

  const [{
    loading: saving,
    error: saveError,
  }, savePrices] = useRequest(saveMinCessionPrices, {
    onSuccess: () => setSuccess(true),
  });

  useEffect(() => {
    fetchDefaultCurrency(user, updateTokens, warehouse);
  }, [warehouse, user, updateTokens]);

  useEffect(() => {
    fetchMinCessionPrices(user, updateTokens, warehouse);
  }, [warehouse, user, updateTokens]);

  useEffect(() => {
    if (data && data.length) {
      setPrices(data);
      return;
    }
    setPrices([]);
  }, [data])

  const addPrice = () => {
    setPrices([...prices, {
      price: 0,
      currency: !prices.length ? currency : null,
    }]);
  }

  const delPrice = (index) => {
    const tmpArr = [ ...prices ];
    tmpArr.splice(index, 1);
    setPrices([...tmpArr]);
  }

  const changePrice = (price, index) => {
    setPrices([
      ...prices.slice(0, index),
      price,
      ...prices.slice(index + 1),
    ]);
  }

  const onSave = () => savePrices(
    user,
    updateTokens,
    {
      warehouse,
      prices,
    }
  );

  const hasError = !!currencyError || !!error || !!saveError;
  const invalidatedPrices = prices.filter(({ price, currency }) => price <= 0 || !currency)

  return (
    <Container className="warehouse-admin">
      <Card>
        <Card.Header>
          <Card.Title>
            <Trans i18nKey="declareToControl.admin.title">
              Warehouse administration
            </Trans>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {hasError && <ErrorMessage error={currencyError || error || saveError} />}
          <Form>
            {prices.map((p, i) => (
              <Form.Group key={i} as={Row} controlId={`min_price_${i}`} className="min-price-row">
                <CessionPriceItem
                  price={p.price}
                  value={p.value}
                  currency={p.currency}
                  delPrice={() => delPrice(i)}
                  onChangePrice={(event) => changePrice(
                    { ...p, price: event.target.value }, i
                  )}
                  onChangeValue={(event) => changePrice(
                    { ...p, value: event.target.value }, i
                  )}
                  onChangeCurrency={(value) => changePrice(
                    { ...p, currency: value }, i
                  )}
                />
              </Form.Group>
            ))}
            <Form.Group as={Row} className="min-price-row">
              <Form.Label column lg="4" className="text-lg-right">
                {!prices.length && (
                  <>
                    <Trans i18nKey="declareToControl.uploading.minPrice">
                      Minimum cession price
                    </Trans>
                    {': '}
                  </>
                )}
              </Form.Label>
              <Button
                className="add-min-price"
                onClick={addPrice}
              >
                +
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer className="p-0 min-price-footer">
          {success && (
            <div className="success-message">
              <i className="vtmn-icon_valid"></i>
              <Trans i18nKey="declareToControl.admin.saved">Saved successfully</Trans>
            </div>
          )}
          <Button
            className="icon-button float-right"
            onClick={onSave}
            disabled={!!invalidatedPrices.length}
          >
            <i className="vtmn-icon_valid"></i>
            <Trans i18nKey="declareToControl.admin.save">Save</Trans>
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
}

export default WarehouseAdmin;
