import React, { useState } from 'react';
import {
  Form,
  Button,
  Modal,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import TextEdit from '../widgets/form/TextEdit';
import SimpleSelect from '../widgets/SimpleSelect';
import FileEditor from '../widgets/FileEditor';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import '../../stylesheets/adminReleases.css';

const RELEASE_TYPES = ['web', 'mobile'];
const RELEASE_OPTIONS = RELEASE_TYPES.map((t) => ({ id: t, label: t }));

const initialContent = {
  version: '',
  type: RELEASE_TYPES[0],
  summary: '',
  image: '',
};

const saveRelease = ({ user, updateTokens, content }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const releaseId = content.id ? `/${content.id}` : '';
  const url = `${process.env.REACT_APP_base_URL}/api/releases${releaseId}`;
  return fetchWithJWT(url, {
    method: content.id ? 'PUT' : 'POST',
    body: JSON.stringify(content),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ReleaseModal = ({ user, updateTokens, show, onClose, afterSave, release }) => {
  const { token } = user;
  const { t } = useTranslation();
  const [content, setContent] = useState(release && release.id ? release : initialContent);

  const [{
    loading: saving,
    error: saveError,
  }, doSaveRelease] = useRequest(saveRelease, {
    onSuccess: onClose,
  });

  const onSave = async () => {
    await doSaveRelease({
      content,
      user,
      updateTokens,
    });
    afterSave && afterSave();
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          <Trans i18nKey="releases.newRelease">New release</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!saveError && <ErrorMessage error={saveError} />}
        <div className="slide-form-wrapper">
          <Form className="slide-form">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="slide-preview">
                {!!content.image && (
                  <img src={`${process.env.REACT_APP_base_URL}/api/files/${content.image}?token=${token}`} alt="slide" />
                )}
              </div>
              <div style={{flex: 1}}>
                <TextEdit
                  label={t('releases.releaseForm.version', 'Version')}
                  value={content.version}
                  onChange={(value) => setContent({ ...content, version: value })}
                />
                <SimpleSelect
                  label={t('releases.releaseForm.type', 'Type')}
                  selected={content.type}
                  options={RELEASE_OPTIONS}
                  onChange={(value) => setContent({ ...content, type: value })}
                />
                <FileEditor
                  id="robot-mass-plan-file"
                  user={user}
                  updateTokens={updateTokens}
                  url={content.image}
                  setUrl={(massPlanFile) => {
                    setContent({ ...content, image: massPlanFile });
                  }}
                />
              </div>
            </div>
            
            <Form.Group className="form-item">
              <Form.Label>
                {t('releases.releaseForm.summary', 'Summary')}
                {': '}
              </Form.Label>
              <div data-color-mode="light">
                <MDEditor
                  value={content.summary}
                  previewOptions={{className: "mobile-markdown"}}
                  className="markdown-editor"
                  onChange={(value) => setContent({ ...content, summary: value })}
                />
              </div>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="releases-modal-footer">
        <Button variant="secondary" className="m-0 mr-2" onClick={onClose}>
          <Trans i18nKey="releases.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          className="m-0 mr-2"
          disabled={saving || !content.version.length || !content.summary.length || !content.type.length}
          onClick={onSave}
        >
          <Trans i18nKey="releases.confirm">Confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReleaseModal;
